@use "../modules/colors";

.container {
  display: flex;
  padding: 1rem;
  border: 1px solid lightgrey;
  border-radius: 15px;
  margin-bottom: 1rem;
  gap: 1rem;
  :first-child {
    margin-top: 0;
  }
}

.content {
  flex-grow: 1;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-end;
  > * {
    margin:0;
    height: 46px;
    width: 46px;
  }
}

.pinned {
  transform: rotateZ(90deg);
}

.japaneseText {
  font-size: 1.3rem;
}

.moreInfo {
  color: colors.$black;
  > * {
    width: 2.5ch;
  }
}