.container {
  border-radius: 15px;
  border: 1px solid lightgrey;
  display: grid;
  gap: 1rem;
}

.image {
  border-radius: 15px 15px 0 0;
  // flex-grow: 1;
  width: 100%;
  max-height: 250px;
  object-fit: cover;
}

.textContent {
  // flex-grow: 3;
  padding-inline: 1rem;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  > * {
    margin-left: 0;
  }
}




@media only screen and (min-width: 600px) {
  .textContent {
    padding-inline: 0;
    padding-block: 1rem;
  }

  .container {
    display: grid;
    grid-template-columns: 1fr 3fr auto;
    gap: 1rem;
  }
  
  .image {
    border-radius: 15px 0 0 15px;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    min-height: 200px;
    min-width: 200px;
    object-fit: cover;
  }

  .buttonContainer {
    flex-direction: column;
    > * {
      margin-left: 1rem;
      margin-top: 0;
    }
  }
}