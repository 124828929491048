@use "../modules/colors";
@use "../modules/sizes";

.placesContainer {
  padding: 1rem;
  display: grid;
  gap: 1rem;
}

.container {
  position: relative;
  max-width: sizes.$maxPageWidth;
  margin: auto;
  background-color: colors.$white;
  h1 {
    margin-top: 0;
  }
}

.intro {
  h1 {
    padding-top: 1rem;
  }
  padding-inline: 1rem;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.maxEntriesWarning {
  color: colors.$secondaryAccent;
}

@media only screen and (min-width: 600px) {
  .placesContainer {
    display: grid;
    gap: 1rem;
  }
}

@media only screen and (min-width: 1000px) {
  .placesContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}
