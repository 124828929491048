@use "./modules/colors";
@use "./modules/sizes";

.main {
  max-width: sizes.$maxPageWidth;
  margin: auto;
  background: colors.$white;
}

.splash {
  background-image: url(/images/sakura.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  padding: 1rem;
  min-height: 400px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.splashText {
  padding: 1rem;
  background-color: colors.$white;
  border-radius: 5px;
  border: 1px solid colors.$primary;
  h1 {
    font-size: clamp(3rem, 19vw, 5rem);
    margin: 0;
    -webkit-text-fill-color: colors.$white;
    -webkit-text-stroke-color: colors.$secondaryAccent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-width: clamp(2px, .7vw, 3px);
    white-space: nowrap;
  }
}

.headerContainer {
  padding-left: 1rem;
}

.cardContainer {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  padding: 1rem;
  gap: 1rem;
}

.featureCard {
  background: colors.$primary;
  padding: 1rem;
  border-radius: 5px;
  color: colors.$primaryAccent;
  h2 {
    text-align: center;
    font-size: clamp(16px, 20%, 24px);
  }
  p {
    text-align:center;
  }
}

.featureCardImage {
  display: flex;
  justify-content: center;
  > * {
    max-width: clamp(100px, 40%, 125px);
    min-height: clamp(100px, 20vw, 150px);
    color: colors.$primaryAccent;
    cursor: pointer;
  }
}

@media only screen and (min-width: 715px) {
  .splash {
    flex-direction: row;
  }

  .splashText {
    width: 50%;
  }

  .cardContainer {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}


