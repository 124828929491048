@use "../modules/colors";
@use "../modules/sizes";

.container {
  background-color: colors.$white;
  max-width: sizes.$maxPageWidth;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.mainButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-block: 1rem;
  * {
    margin: 0;
  }
}

.centerVertically {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    margin-top: 0;
    .success {
      margin-left: auto;
    }
  }
}

.kanaDisplay {
  font-size: 5rem;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
}

.collections {
  display: flex;
  justify-content: center;
  gap: 1rem;
  div {
    display: flex;
    flex-direction: column;
    // gap: .5rem;
    label {
      display: flex;
      align-items: center;
      margin: 0;
    }
  }
}

.kanaStats {
  >:nth-child(1), >:nth-child(3) {
    background-color: lightgrey;
  }
  p {
    margin: 0;
  }
}

.persistentStats {
  display: flex;
  justify-content: space-between;
}

.kanaToReview {
  border-top: 3px solid colors.$tertiary;
  margin-top: .5rem;
  display: block;
  
}



.responseDisplay {
  min-height: 65px;
}

.responses {
  display: flex;
  gap: 1rem;
}

.correctResponse {
  // display: none;
  margin: 0;
  color: colors.$success;
  opacity: 0;
}

.incorrectResponse {
  // display: none;
  margin: 0;
  color: colors.$danger;
  opacity: 0;
}

.correctReading {
  margin: 0;
  opacity: 0;
  // display: none;
}

.showResponse {
  // display: block;
  opacity: 1;
  // animation-name: fadeout;
  // animation-duration: 2s;
  // animation-fill-mode: forwards;
}

.mainInputContainer {
  max-width: min(256px, 100% - 1rem);
  input {
    width: 100%;
  }
}


@keyframes fadeout {
  from {
    color: colors.$secondary;
  }
  to {
    color: transparent;
  }
}

@media only screen and (min-width: 870px) {
  .collections {
    justify-content: center;
    div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 1rem;
    }
  }
}